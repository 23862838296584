import { ContextApi } from 'contexts/Localization/types'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'Admin Bundhaya Speed Boat',
  description:
    'Admin Bundhaya Speed Boat',
}

export const getCustomMeta = (path: string, t?: ContextApi['t']): PageMeta => {
  switch (path) {
    default:
      return null
  }
}
