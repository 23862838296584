import React from 'react'
import { ThemeProvider as SCThemeProvider } from 'styled-components'
import { light } from '@pancakeswap/uikit'

const ThemeContext = React.createContext({ isDark: null, toggleTheme: () => null })

const theme = {
  ...light,
  shadows: {
    level1: "0px 2px 12px -8px rgba(25, 19, 38, 0.1), 0px 1px 1px rgba(25, 19, 38, 0.05)",
    active: "0px 0px 0px 1px #0098A1, 0px 0px 4px 8px rgba(31, 199, 212, 0.4)",
    success: "0px 0px 0px 1px #31D0AA, 0px 0px 0px 4px rgba(49, 208, 170, 0.2)",
    warning: "0px 0px 0px 1px #FF1D1D, 0px 0px 0px 4px rgba(237, 75, 158, 0.2)",
    focus: "0px 0px 0px 1px #00A7F5, 0px 0px 0px 4px rgba(0, 167, 245, 0.6)",
    inset: "inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1)",
  },
  colors: {
    failure: '#FF1D1D',
    primary: '#00A7F5',
    primaryBright: '#53DEE9',
    primaryDark: '#0098A1',
    secondary: '#FFC81D',
    success: '#31D0AA',
    warning: '#FFB237',
    background: '#FAF9FA',
    backgroundDisabled: '#E9EAEB',
    backgroundAlt: '#FFFFFF',
    cardBorder: '#E7E3EB',
    contrast: '#191326',
    dropdown: '#F6F6F6',
    dropdownDeep: '#EEEEEE',
    invertedContrast: '#FFFFFF',
    input: '#f7f7f7',
    inputSecondary: '#e9f7fd',
    tertiary: '#EFF4F5',
    text: '#000',
    textDisabled: '#BDC2C4',
    textSubtle: '#929292',
    disabled: '#E9EAEB',
    gradients: {
      bubblegum: 'linear-gradient(139.73deg, #E5FDFF 0%, #F3EFFF 100%)',
      inverseBubblegum: 'linear-gradient(139.73deg, #F3EFFF 0%, #E5FDFF 100%)',
      cardHeader: 'linear-gradient(111.68deg, #F2ECF2 0%, #E8F2F6 100%)',
      blue: 'linear-gradient(180deg, #A7E8F1 0%, #94E1F2 100%)',
      violet: 'linear-gradient(180deg, #E2C9FB 0%, #CDB8FA 100%)',
      violetAlt: 'linear-gradient(180deg, #CBD7EF 0%, #9A9FD0 100%)',
      gold: 'linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)',
    },
    binance: '#F0B90B',
    overlay: '#000',
  },
}

const ThemeContextProvider = ({ children }) => {
  return <SCThemeProvider theme={theme}>{children}</SCThemeProvider>
}

export { ThemeContext, ThemeContextProvider }
